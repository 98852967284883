<script>
import ExperienceManager from "../experience-manager.vue";

export default {
    name: "experiences-card",

    components: {ExperienceManager},

    props: {
        userId: {
            type: [Number, String],
            required: true
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title text-muted">
                <i class="fas fa-passport me-2"></i>
                <span>
                    {{ $tc('organizations::experiences.experience', 2).ucFirst() }}
                </span>
            </h5>
        </div>
        <div class="card-body rounded-bottom bg-light">
            <experience-manager :user-id="userId"></experience-manager>
        </div>
    </div>
</template>

<style scoped>

</style>
