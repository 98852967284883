<script>
import {mapGetters} from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Adapter from '@/api/upload.js';
import '@/translations/ckeditor.js';

export default {
    name: "introduction-form",

    emits: ['saved', 'update', 'add'],

    props: {
        item: {
            type: Object,
            required: true
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        fix: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ckeditor: CKEditor.component
    },

    computed: {
        ...mapGetters({
            loading: 'introduction/loading',
            states: 'introduction/states',
            errors: 'introduction/errors',
            users: 'user/all',
            locale: 'trans/locale'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        editorConfig:  function () {
            return {
                extraPlugins: [this.uploader],
                language: this.locale,
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                    ]
                }
            };
        },
    },

    data: function () {
        return {
            model: {},
            editor: ClassicEditor,
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)
            if (!this.doNotSave) {
                return this.$store.dispatch(`introduction/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                })
            } else {
                if (this.model.id) {
                    this.$emit('update', this.model);
                } else {
                    this.$emit('add', this.model);
                }
            }
        },

        setModel: function (model = {}) {
            const base = {}

            this.model = Object.assign(
                {},
                base,
                JSON.parse(JSON.stringify(model))
            )
        },

        fetchUsers: function () {
            this.$store.dispatch('user/all');
        },

        uploader(editor)
        {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new Adapter( loader );
            };
        },
    },

    watch: {
        item: function (value) {
            this.setModel(value)
        },

        model: {
            deep: true,
            immediate: true,
            handler: function (value) {
                this.$emit('update', Object.assign({}, value))
            },
        }
    },

    created() {
        this.setModel(this.item)
        if (!this.fix) {
            this.fetchUsers();
        }
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div v-if="!fix" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.user_id === false}"
                        class="form-label"
                        for="user_id"
                    >{{ $t('organizations::experiences.columns.user_id').ucFirst() }}</label>
                    <select
                        id="user_id"
                        v-model="model.user_id"
                        :class="{'border-danger': states.user_id === false}"
                        class="form-select"
                        name="user_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('organizations::experiences.placeholders.user_id').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in users" :key="key" :value="item.id">{{
                                item.translation_key ? $t(item.translation_key).ucFirst() : item.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">
                        {{ errors.user_id }}
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <label
                        :class="{'text-danger': states.greeting === false}"
                        class="form-label"
                        for="greeting"
                    >{{ $t('organizations::introductions.columns.greeting').ucFirst() }}</label
                    >
                    <textarea
                        id="greeting"
                        v-model="model.greeting"
                        :class="{'border-danger': states.greeting === false}"
                        :placeholder="$t('organizations::introductions.placeholders.greeting').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.greeting === false}" class="invalid-feedback">
                        {{ errors.greeting }}
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <label
                        :class="{'text-danger': states.introduction === false}"
                        class="form-label"
                        for="introduction"
                    >{{ $t('organizations::introductions.columns.introduction').ucFirst() }}</label
                    >
                    <ckeditor
                        id="introduction"
                        :class="{'border-danger': states.introduction === false}"
                        v-model="model.introduction"
                        :editor="editor"
                        :config="editorConfig"
                    >
                        class="form-control rounded"
                        :placeholder="$t('organizations::introductions.placeholders.introduction').ucFirst()"
                    </ckeditor>
                    <div :class="{'d-block': states.introduction === false}" class="invalid-feedback">
                        {{ errors.introduction }}
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <label
                        :class="{'text-danger': states.interests === false}"
                        class="form-label"
                        for="interests"
                    >{{ $t('organizations::introductions.columns.interests').ucFirst() }}</label
                    >
                    <ckeditor
                        id="interests"
                        :class="{'border-danger': states.interests === false}"
                        v-model="model.interests"
                        :editor="editor"
                        :config="editorConfig"
                    >
                        class="form-control rounded"
                        :placeholder="$t('organizations::introductions.placeholders.interests').ucFirst()"
                    </ckeditor>
                    <div :class="{'d-block': states.interests === false}" class="invalid-feedback">
                        {{ errors.interests }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
