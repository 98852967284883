<script>
import {mapGetters} from "vuex";
import IconSelect from "../icon-select.vue";

export default {
    name: "target-values",
    components: {IconSelect},

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            quantityTargetValues: 'quantityTargetValue/all',
            quantityTargetValueLoading: 'quantityTargetValue/loading',
            financialTargetValues: 'financialTargetValue/all',
            financialTargetValueLoading: 'financialTargetValue/loading',
            customTargetValues: 'customTargetValue/all',
            customTargetValueLoading: 'financialTargetValue/loading',
            locale: 'trans/locale',
            currency: 'currency/item'
        }),

        fields: function () {
            return [
                '',
                this.$t('base.type').ucFirst(),
                this.$tc('projects::quantity_target_values.quantity_target_value', 1).ucFirst(),
            ]
        },
    },

    methods: {
        fetchFinancialTargetValues: function () {
            this.$store.dispatch('financialTargetValue/userItems', {id: this.user?.id, query: { currency_id: this.currency?.id, with: ['interaction', 'currency'], minimal: true}})
        },
    },

    watch: {
        currency: {
            deep: true,
            immediate: true,
            handler: function () {
                this.fetchFinancialTargetValues();
            },
        }
    },

    created() {
        this.$store.dispatch('quantityTargetValue/userItems', {id: this.user?.id, query: {with: ['interaction'], minimal: true}})
        this.$store.dispatch('customTargetValue/userItems', {id: this.user?.id, query: {minimal: true}})
        this.$store.dispatch('currency/all').then(currencies => {
            this.$store.dispatch('currency/setItem', currencies?.[0])
        })
    },

    unmounted() {
        this.$store.dispatch('quantityTargetValue/clearAll')
        this.$store.dispatch('financialTargetValue/clearAll')
        this.$store.dispatch('customTargetValue/clearAll')
    }
}
</script>

<template>
    <div class="row" v-if="user?.id">
        <div class="col-xl-6">
            <b-overlay :show="quantityTargetValueLoading">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5 class="card-title">{{ $tc('projects::quantity_target_values.quantity_target_value', 1).ucFirst() }}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover table-borderless table-sm manager">
                            <thead>
                            <tr>
                                <td class="pb-3 text-center font-weight-bold" v-for="(field, fieldIndex) in fields" :key="fieldIndex">{{ field }}</td>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-if="quantityTargetValues?.length">
                                <tr v-for="(item, index) in quantityTargetValues" :key="item.id || 'index_' + index">
                                    <td>{{ item.interaction?.translation_key ? $t(item.interaction.translation_key).ucFirst() : item.interaction?.name }}</td>
                                    <td>
                                        <template v-if="item.type">{{ $t('dates.' + item.type).ucFirst() }}</template>
                                        <i v-else class="fas fa-ban text-dark opacity-50"></i>
                                    </td>
                                    <td class="text-end">
                                        <template v-if="item.value">{{ item.value }}</template>
                                        <i v-else class="fas fa-ban text-dark opacity-50"></i>
                                    </td>
                                </tr>
                            </template>
                            <tr v-else>
                                <td :colspan="fields.length" class="text-center">{{ $t('base.empty_table').ucFirst() }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div class="col-xl-6">
            <b-overlay :show="financialTargetValueLoading">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5 class="card-title">{{ $tc('projects::financial_target_values.financial_target_value', 1).ucFirst() }}</h5>
                            <icon-select store="currency" notNull content translatable icon="symbol" text="name"></icon-select>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover table-borderless table-sm manager">
                            <thead>
                            <tr>
                                <td class="pb-3 text-center font-weight-bold" v-for="(field, fieldIndex) in fields" :key="fieldIndex">{{ field }}</td>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-if="financialTargetValues?.length">
                                <tr v-for="(item, index) in financialTargetValues" :key="item.id || 'index_' + index">
                                    <td>{{ item.interaction?.translation_key ? $t(item.interaction.translation_key).ucFirst() : item.interaction?.name }}</td>
                                    <td>
                                        <template v-if="item.type">{{ $t('dates.' + item.type).ucFirst() }}</template>
                                        <i v-else class="fas fa-ban text-dark opacity-50"></i>
                                    </td>
                                    <td class="text-end">
                                        <template v-if="item.value">{{ item.value?.printCurrency(item.currency?.code, locale) }}</template>
                                        <i v-else class="fas fa-ban text-dark opacity-50"></i>
                                    </td>
                                </tr>
                            </template>
                            <tr v-else>
                                <td :colspan="fields.length" class="text-center">{{ $t('base.empty_table').ucFirst() }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        <h5 class="card-title">{{ $tc('projects::custom_target_values.custom_target_value', 1).ucFirst() }}</h5>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-striped table-hover table-borderless table-sm manager">
                        <thead>
                        <tr>
                            <td class="pb-3 text-center font-weight-bold" v-for="(field, fieldIndex) in fields" :key="fieldIndex">{{ field }}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="customTargetValues?.length">
                            <tr v-for="(item, index) in customTargetValues" :key="item.id || 'index_' + index">
                                <td>{{ item.translation_key ? $t(item.translation_key).ucFirst() : item.name }}</td>
                                <td>
                                    <template v-if="item.type">{{ $t('dates.' + item.type).ucFirst() }}</template>
                                    <i v-else class="fas fa-ban text-dark opacity-50"></i>
                                </td>
                                <td class="text-end">
                                    <template v-if="item.value">{{ item.value }}</template>
                                    <i v-else class="fas fa-ban text-dark opacity-50"></i>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td :colspan="fields.length" class="text-center">{{ $t('base.empty_table').ucFirst() }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
