<script>
import {mapGetters} from "vuex";

export default {
    name: "superior-card",

    props: {
        user: {
            type: Object
        }
    },

    data: function () {
        return {
            superiorUser: null
        };
    },

    computed: {
        ...mapGetters({
            profile: 'user/profile',
            loading: 'user/loading'
        }),

        superior: function () {
            return this.superiorUser || this.user?.superior || this.profile?.superior
        },
    },

    methods: {
        fetchSuperior: function () {
            this.$store.dispatch('user/superior').then(response => {
                this.superiorUser = response;
            })
        },
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (user) {
                if (user?.id && !user.superior) {
                    this.fetchSuperior();
                }
            },
        }
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0"><i class="fas fa-user-tie me-2"></i>{{ $t('users.superior').ucFirst() }}</h5>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table v-if="superior?.id" class="table align-middle table-nowrap mb-0">
                        <tbody>
                        <tr>
                            <td class="border-bottom-0" style="width: 50px">
                                <router-link :to="$t('routes./profiles/:id(\\d+)', {id: superior?.id}).rmValidation()">
                                    <img v-if="superior?.image" :alt="superior?.name" :src="superior?.image"
                                         :title="superior?.name" class="rounded-circle avatar-sm"/>
                                    <div v-else class="avatar-sm d-inline-block cursor-pointer" :title="superior?.name">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-white font-size-14"> {{
                                            superior?.name?.firstLetters()
                                        }} </span>
                                    </div>
                                </router-link>
                            </td>
                            <td class="border-bottom-0">
                                <div class="d-flex flex-column">
                                    <h5 class="font-size-14 m-0">
                                        <router-link class="text-dark" :to="$t('routes./profiles/:id(\\d+)', {id: superior?.id}).rmValidation()">
                                            {{ superior?.name?.truncate(40) }}
                                        </router-link>
                                    </h5>
                                    <span class="text-muted font-size-10">
                                        <i v-if="superior.position?.icon" :class="superior.position?.icon" :style="{color: superior.position?.color}" class="me-1 font-size-9"></i>
                                        {{ (superior.position?.translation_key ? $t(superior.position.translation_key).ucFirst() : superior.position?.name) }}
                                    </span>
                                </div>
                            </td>
                            <td class="border-bottom-0">
                                <div>
                                    <span v-if="superior.specialization" class="font-size-10 text-center px-2 badge" :style="{background: superior.specialization?.color}">
                                        <i v-if="superior.specialization?.icon" :class="superior.specialization?.icon" class="me-1 font-size-9"></i>
                                        {{ (superior.specialization?.translation_key ? $t(superior.specialization.translation_key).ucFirst() : superior.specialization?.name) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0" colspan="3">
                                <div class="w-100 font-size-10 text-center px-2 bg-light text-muted rounded-top">
                                    <i v-if="superior.knowledge_level?.icon" :class="superior.knowledge_level?.icon" :style="{color: superior.knowledge_level?.color}" class="me-1 font-size-9"></i>
                                    {{ (superior.knowledge_level?.translation_key ? $t(superior.knowledge_level.translation_key).ucFirst() : superior.knowledge_level?.name) }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-else class="w-100 text-center">
                        <i class="fas fa-ban text-danger font-size-20"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- end card -->
    </b-overlay>
</template>

<style scoped>

</style>
