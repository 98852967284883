<script>
import {mapGetters} from "vuex";
import Avatar from "./avatar.vue";

export default {
    name: "subordinates-card",
    components: {Avatar},

    props: {
        user: {
            type: Object
        }
    },

    data: function () {
        return {
            subordinateUsers: null,
        }
    },

    computed: {
        ...mapGetters({
            profile: 'user/profile',
            loading: 'user/loading'
        }),

        item: function () {
            return this.user || this.profile
        },

        subordinates: function () {
            return this.subordinateUsers || this.item?.subordinates || []
        },
    },

    methods: {
        fetchSubordinates: function () {
            this.$store.dispatch('user/subordinates').then(response => {
                this.subordinateUsers = response;
            })
        },
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (user) {
                if (user?.id && !user.superior) {
                    this.fetchSubordinates();
                }
            },
        }
    },
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0"><i class="fas fa-users me-2"></i>{{ $t('users.subordinates').ucFirst() }}</h5>
            </div>

            <div class="card-body p-2">
                <div v-if="subordinates?.length" class="table-responsive">
                    <table class="table align-middle table-nowrap mb-0">
                        <tbody>
                        <template v-for="(subordinate, index) in subordinates" :key="index">
                            <tr>
                                <td class="border-bottom-0" style="width: 50px">
                                    <avatar :user="subordinate" hover>
                                        <template #hover>
                                            <div
                                                v-if="subordinate?.icon"
                                                class="avatar-hover d-none avatar-title rounded-circle text-white font-size-16 user-select-none opacity-75"
                                                :style="{background: subordinate?.color}"
                                            >
                                                <i :class="subordinate.icon"></i>
                                            </div>
                                        </template>
                                    </avatar>
                                </td>
                                <td class="border-bottom-0">
                                    <div class="d-flex flex-column">
                                        <h5 class="font-size-14 m-0">
                                            <router-link class="text-dark" :to="$t('routes./profiles/:id(\\d+)', {id: subordinate.id}).rmValidation()">
                                                {{ subordinate.name?.truncate(40) }}
                                            </router-link>
                                        </h5>
                                        <span class="text-muted font-size-10">
                                            <i v-if="subordinate.position?.icon" :class="subordinate.position?.icon" :style="{color: subordinate.position?.color}" class="me-1 font-size-9"></i>
                                            {{ (subordinate.position?.translation_key ? $t(subordinate.position.translation_key).ucFirst() : subordinate.position?.name) }}
                                        </span>
                                    </div>
                                </td>
                                <td class="border-bottom-0">
                                    <div class="d-flex flex-column justify-content-center align-items-stretch rounded">
                                        <span v-if="subordinate.specialization" class="font-size-10 text-center px-2 badge" :style="{background: subordinate.specialization?.color}">
                                            <i v-if="subordinate.specialization?.icon" :class="subordinate.specialization?.icon" class="me-1 font-size-9"></i>
                                            {{ (subordinate.specialization?.translation_key ? $t(subordinate.specialization.translation_key).ucFirst() : subordinate.specialization?.name) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0" colspan="3">
                                    <div class="w-100 font-size-10 text-center px-2 bg-light text-muted rounded-top">
                                        <i v-if="subordinate.knowledge_level?.icon" :class="subordinate.knowledge_level?.icon" :style="{color: subordinate.knowledge_level?.color}" class="me-1 font-size-9"></i>
                                        {{ (subordinate.knowledge_level?.translation_key ? $t(subordinate.knowledge_level.translation_key).ucFirst() : subordinate.knowledge_level?.name) }}
                                    </div>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <div v-else class="w-100 text-center">
                    <i class="fas fa-ban text-danger font-size-20"></i>
                </div>
            </div>
        </div>
        <!-- end card -->
    </b-overlay>
</template>

<style scoped>

</style>
