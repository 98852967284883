<script>
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";

export default {
    name: "experience-form",

    components: {IconInput},

    emits: ['saved', 'update', 'add'],

    props: {
        item: {
            type: Object,
            required: true
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        fix: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'experience/loading',
            states: 'experience/states',
            errors: 'experience/errors',
            users: 'user/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)
            if (!this.doNotSave) {
                return this.$store.dispatch(`experience/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                })
            } else {
                if (this.model.id) {
                    this.$emit('update', this.model);
                } else {
                    this.$emit('add', this.model);
                }
            }
        },

        setModel: function (model = {}) {
            const base = {}

            this.model = Object.assign(
                {},
                base,
                JSON.parse(JSON.stringify(model))
            )
        },

        fetchUsers: function () {
            this.$store.dispatch('user/all');
        },
    },

    watch: {
        item: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.item)
        this.fetchUsers();
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div v-if="!fix" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.user_id === false}"
                        class="form-label"
                        for="user_id"
                    >{{ $t('organizations::experiences.columns.user_id').ucFirst() }}</label>
                    <select
                        id="user_id"
                        v-model="model.user_id"
                        :class="{'border-danger': states.user_id === false}"
                        class="form-select"
                        name="user_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('organizations::experiences.placeholders.user_id').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in users" :key="key" :value="item.id">{{
                                item.translation_key ? $t(item.translation_key).ucFirst() : item.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">
                        {{ errors.user_id }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.company === false}"
                        class="form-label"
                        for="company"
                    >{{ $t('organizations::experiences.columns.company').ucFirst() }}</label
                    >
                    <input
                        id="company"
                        v-model="model.company"
                        :class="{'border-danger': states.company === false}"
                        :placeholder="$t('organizations::experiences.placeholders.company').ucFirst()"
                        class="form-control"
                        name="company"
                        type="text"
                    />
                    <div :class="{'d-block': states.company === false}" class="invalid-feedback">{{ errors.company }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.position === false}"
                        class="form-label"
                        for="position"
                    >{{ $t('organizations::experiences.columns.position').ucFirst() }}</label
                    >
                    <input
                        id="position"
                        v-model="model.position"
                        :class="{'border-danger': states.position === false}"
                        :placeholder="$t('organizations::experiences.placeholders.position').ucFirst()"
                        class="form-control"
                        name="position"
                        type="text"
                    />
                    <div :class="{'d-block': states.position === false}" class="invalid-feedback">{{ errors.position }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.from === false}"
                        class="form-label"
                        for="from"
                    >{{ $t('organizations::experiences.columns.from').ucFirst() }}</label
                    >
                    <input
                        id="from"
                        v-model="model.from"
                        :class="{'border-danger': states.from === false}"
                        :placeholder="$t('organizations::experiences.placeholders.from').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.from === false}" class="invalid-feedback">
                        {{ errors.from }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.to === false}"
                        class="form-label"
                        for="to"
                    >{{ $t('organizations::experiences.columns.to').ucFirst() }}</label
                    >
                    <input
                        id="to"
                        v-model="model.to"
                        :class="{'border-danger': states.to === false}"
                        :placeholder="$t('organizations::experiences.placeholders.to').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.to === false}" class="invalid-feedback">
                        {{ errors.to }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.summary === false}"
                        class="form-label"
                        for="summary"
                    >{{ $t('organizations::experiences.columns.summary').ucFirst() }}</label
                    >
                    <textarea
                        id="summary"
                        v-model="model.summary"
                        :class="{'border-danger': states.summary === false}"
                        :placeholder="$t('organizations::experiences.placeholders.summary').ucFirst()"
                        class="form-control"
                        name="summary"
                    />
                    <div :class="{'d-block': states.summary === false}" class="invalid-feedback">{{ errors.summary }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.color === false}"
                        class="form-label"
                        for="color"
                    >{{ $t('organizations::experiences.columns.color').ucFirst() }}</label
                    >
                    <input
                        id="color"
                        v-model="model.color"
                        :class="{'border-danger': states.color === false}"
                        :placeholder="$t('organizations::experiences.placeholders.color').ucFirst()"
                        class="form-control form-control-color"
                        name="color"
                        type="color"
                    />
                    <div :class="{'d-block': states.color === false}" class="invalid-feedback">{{ errors.color }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <icon-input
                        id="icon"
                        v-model="model.icon"
                        :class="{'border-danger': states.icon === false}"
                        :placeholder="$t('organizations::experiences.placeholders.icon').ucFirst()"
                        class="form-control"
                        name="icon"
                        :state="states.icon"
                        :error="errors.icon"
                    >{{ $t('organizations::experiences.columns.icon').ucFirst() }}</icon-input>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is_active"
                    >{{ $t('organizations::experiences.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="is_active"
                               v-model="model.is_active"
                               class="form-check-input float-none mx-3"
                               type="checkbox"
                        >
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
