<script>
import {mapGetters} from "vuex";
import UserForm from "@/components/forms/user-form.vue";
import TabCard from "../../../components/elements/tab-card.vue";
import TabNav from "../../../components/elements/tab-nav.vue";
import TabPanel from "../../../components/elements/tab-panel.vue";
import TargetValues from "../../../components/elements/user/target-values.vue";
import SuperiorCard from "../../../components/elements/user/superior-card.vue";
import SubordinatesCard from "../../../components/elements/user/subordinates-card.vue";
import IntroductionCard from "../../../components/elements/user/introduction-card.vue";
import ExperiencesCard from "../../../components/elements/user/experiences-card.vue";
import SkillManager from "../../../components/elements/user/skill-manager.vue";
import PositionManager from "../../../components/elements/user/position-manager.vue";

export default {
    computed: {
        ...mapGetters({
            'authenticated': 'user/authenticated',
            'firstLetter': 'user/firstLetter'
        }),

        editable: function () {
            return JSON.parse(JSON.stringify(this.authenticated))
        },
    },

    data() {
        return {};
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'users.user', count: 1})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    },

    components: {
        PositionManager, SkillManager,
        ExperiencesCard,
        IntroductionCard,
        SubordinatesCard,
        SuperiorCard,
        TargetValues,
        TabPanel,
        TabNav,
        TabCard,
        UserForm,
    },
};
</script>

<template>
    <div class="row">
        <div class="col-xxl-3">
            <div class="user-sidebar">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="user-profile-img">
                            <img
                                alt=""
                                class="profile-img profile-foreground-img rounded-top"
                                src="@/assets/images/pattern-bg.jpg"
                                style="height: 120px"
                            />
                            <div class="overlay-content rounded-top">

                            </div>
                        </div>
                        <!-- end user-profile-img -->

                        <div class="mt-n5 position-relative">
                            <div class="text-center">
                                <img v-if="authenticated.image" :alt="authenticated.name" :src="authenticated.image"
                                     :title="authenticated.name" class="avatar-xl rounded-circle img-thumbnail"/>
                                <div v-else class="avatar-lg d-inline-block">
                                    <span class="avatar-title rounded-circle bg-soft-secondary text-white font-size-26"> {{
                                            firstLetter
                                        }} </span>
                                </div>

                                <div class="mt-3">
                                    <h5 class="mb-1">{{ authenticated.name }}</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->

            </div>
        </div>

        <div class="col-xxl-9">

            <tab-card>
                <template #nav>
                    <tab-nav active name="edit-profile" icon="bx bx-edit-alt font-size-20" :text="$t('base.edit').ucFirst()"></tab-nav>
                    <tab-nav name="hierarchy" icon="bx bx-sitemap font-size-20" :text="$tc('users.organogram',2).ucFirst()"></tab-nav>
                    <tab-nav name="career" icon="bx bxs-user-detail font-size-20" :text="$tc('organizations::careers.career',1).ucFirst()"></tab-nav>
                    <tab-nav name="targets" icon="bx bx-bullseye font-size-20" :text="$tc('projects::target_values.target_value',2).ucFirst()"></tab-nav>
                </template>
                <template #content>
                    <tab-panel active name="edit-profile">
                        <UserForm :user="editable"></UserForm>
                    </tab-panel>
                    <tab-panel name="hierarchy">
                        <div class="row">
                            <div class="col-lg-6">
                                <superior-card :user="editable"></superior-card>
                            </div>
                            <div class="col-lg-6">
                                <subordinates-card :user="editable"></subordinates-card>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <position-manager :user-id="editable?.id"></position-manager>
                            </div>
                            <div class="col-lg-6">
                                <skill-manager :user-id="editable?.id"></skill-manager>
                            </div>
                        </div>
                    </tab-panel>
                    <tab-panel name="career">
                        <div class="row">
                            <div class="col-12">
                                <introduction-card :user-id="editable?.id"></introduction-card>
                            </div>
                            <div class="col-12">
                                <experiences-card :user-id="editable?.id"></experiences-card>
                            </div>
                        </div>
                    </tab-panel>
                    <tab-panel name="targets">
                        <target-values :user="editable"></target-values>
                    </tab-panel>
                </template>

            </tab-card>
        </div>
    </div>
</template>
