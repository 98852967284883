<script>
import TransitionToggle from "../transitions/toggle.vue";
import SimpleTable from "../elements/simple-table.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import ExperienceForm from "../forms/experience-form.vue";
export default {
    name: "experience-manager",


    components: {ExperienceForm, SimpleTable, TransitionToggle},

    props: {
        userId: {
            type: [String, Number]
        }
    },

    computed: {
        ...mapGetters({
            loading: 'experience/loading',
            items: 'experience/all',
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: 'company',
                    label: this.$t('organizations::experiences.columns.company').ucFirst(),
                },
                {
                    key: 'position',
                    label: this.$t('organizations::experiences.columns.position').ucFirst()
                },
                {
                    key: 'from',
                    label: this.$t('organizations::experiences.columns.from').ucFirst(),
                    formatter: (value) => value?.printDate(this.locale, {dateStyle: 'short'}),
                },
                {
                    key: 'to',
                    label: this.$t('organizations::experiences.columns.to').ucFirst(),
                    formatter: (value) => value?.printDate(this.locale, {dateStyle: 'short'}),
                },
                {
                    key: 'icon',
                    label: this.$t('organizations::experiences.columns.icon').ucFirst()
                },
                {
                    key: 'color',
                    label: this.$t('organizations::experiences.columns.color').ucFirst()
                },
                {
                    key: 'is_active',
                    label: this.$t('organizations::experiences.columns.is_active').ucFirst()
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    tdClass: 'text-end'
                }
            ];
        },
    },

    data: function () {
        return {
            showForm: false,
            item: {
                user_id: this.userId
            },
            showModal: false,
            title: '',
            subtitle: '',
            content: ''
        }
    },

    methods: {
        readDescription: function (item) {
            this.title = item?.company
            this.subtitle = item?.position + ' ' + item?.from?.printDate(this.locale, {year: 'numeric', month: 'numeric'}) + '-' + item?.from?.printDate(this.locale, {year: 'numeric', month: 'numeric'})
            this.content = item?.summary
            this.showModal = true
        },

        editTableItem: function (item, callback) {
            this.$store.dispatch('experience/setItem', item).then(() => {
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('experience/destroy', item.id)
                }
            });
        },

        updateTableItem: function (item, callback) {
            this.$store.dispatch('experience/update', item).then(() => {
                this.$store.dispatch('experience/clearItem')
                callback()
            })
        },

        add: function (item) {
            this.$store.dispatch('experience/create', item).then(() => {
                this.$store.dispatch('experience/clearItem')
                this.showForm = false
            })
        },

        clearItem: function () {
            this.item = {
                user_id: this.userId
            }
            this.$store.dispatch('experience/clearItem')
        },

        saveTableItemsSorting: function (items) {
            this.$store.dispatch('experience/saveSorting', items);
        },
    },

    created() {
        this.$store.dispatch('experience/all', {user_id: this.userId})
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <simple-table :items="items" :fields="fields" sortable @sorted="saveTableItemsSorting">
            <template #actions="{item, edit}">
                <span class="text-info cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="readDescription(item)">
                    <i class="bx bx-detail"></i>
                </span>
                <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                    <i class="fas fa-edit"></i>
                </span>
                <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                    <i class="fas fa-trash-alt"></i>
                </span>
            </template>
            <template #default="{close, item}">
                <experience-form :item="item" fix do-not-save @update="updated => updateTableItem(updated, close)">
                    <template #buttons>
                        <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                    </template>
                </experience-form>
            </template>
            <template #icon="{value}">
                <i v-if="value" :class="value"></i>
            </template>
            <template #color="{value}">
                <span v-if="value" class="cube-20" :style="{background: value}"></span>
            </template>
            <template #is_active="{value}">
                <i v-if="value === true" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-times-circle text-danger"></i>
            </template>
        </simple-table>
        <transition-toggle>
            <experience-form v-if="showForm" fix do-not-save class="border rounded p-3 bg-white" :item="item" @add="add">
                <template #buttons>
                    <b-button class="ms-2" variant="outline-secondary" @click="showForm = !showForm">{{ $t('base.cancel').ucFirst() }}</b-button>
                </template>
            </experience-form>
        </transition-toggle>
        <transition-toggle>
            <div class="col-12 mb-2 text-center" v-if="!showForm">
                <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new').ucFirst() }}</b-button>
            </div>
        </transition-toggle>
        <b-modal v-model="showModal" :title="title" hide-footer>
            <h6>{{ subtitle }}</h6>
            {{ content }}
        </b-modal>
    </b-overlay>
</template>
<style scoped>
.cube-20 {
    display: inline-block;
    width: 20px;
    aspect-ratio: 1 / 1;
}
</style>
