<script>

import IntroductionForm from "../../forms/introduction-form.vue";
import {mapGetters} from "vuex";

export default {
    name: "introduction-card",

    components: {IntroductionForm},

    props: {
        userId: {
            type: [Number, String],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            item: 'introduction/item'
        }),

        fixed: function () {
            return !this.item?.user_id ? Object.assign({user_id: this.userId}, this.item) : this.item;
        },
    },

    methods: {
        fetch: function () {
            this.$store.dispatch('introduction/userIntroduction', this.userId)
        },
    },

    watch: {
        userId: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.fetch()
                } else {
                    this.$store.dispatch('introduction/clearItem')
                }
            }
        }
    },

    unmounted() {
        this.$store.dispatch('introduction/clearItem')
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title text-muted">
                <i class="fas fa-house-user me-2"></i>
                <span>
                    {{ $tc('organizations::introductions.introduction', 1).ucFirst() }}
                </span>
            </h5>
        </div>
        <div class="card-body bg-light rounded-bottom">
            <introduction-form :item="fixed" fix></introduction-form>
        </div>
    </div>
</template>

<style scoped>

</style>
