<script>
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: "icon-select",
    components: {TransitionToggle},

    props: {
        list: {
            type: Array
        },

        store: {
            type: String
        },

        content: {
            type: Boolean
        },

        icon: {
            type: String
        },

        text: {
            type: String
        },

        size: {
            type: String,
            default: '40px'
        },

        translatable: {
            type: Boolean
        },

        fontSize: {
            type: String,
            default: '24px'
        },

        notNull: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            selected: null,
            show: false
        };
    },

    computed: {
        items: function () {
            return this.list?.length
                ? this.list
                : this.$store.getters[this.store + '/all'];
        },
    },

    methods: {
        fetchStore: function () {
            this.$store.dispatch(this.store + '/all').then(response => {
                if (this.notNull && !this.selected) {
                    this.selected = response?.[0];
                    this.$store.dispatch(this.store + '/setItem', this.selected);
                }
            });
        },

        select: function (item) {
            if (this.store) {
                this.$store.dispatch(this.store + '/setItem', item);
            }

            this.selected = item;
            this.show =  false;
        },

        initValue: function () {
            const item = this.$store.getters[this.store + '/item'];

            if (item?.id) {
                this.selected = item;
            }
        },
    },

    created() {
        this.initValue();
        this.fetchStore();
    }
}
</script>

<template>
    <span class="d-inline-block position-relative">
        <div @click="show = !show" class="d-inline-flex align-items-center justify-content-center p-2 border rounded bg-gradient bg-soft-primary border-0 cursor-pointer" :style="{width: size, height: size, 'font-size': fontSize}">
            <template v-if="selected">
                <i v-if="!content" :class="selected?.[icon]"></i>
                <strong v-else>{{ selected?.[icon] }}</strong>
            </template>
            <template v-else>
                <i class="fas fa-question"></i>
            </template>
        </div>
        <transition-toggle>
            <div v-show="show" class="bg-white border rounded p-2 position-absolute icon-list">
                <div @click="select(item)" class="icon-list-item cursor-pointer px-2" v-for="(item, index) in items" :key="index">
                    {{ translatable ? (item.translation_key ? $t(item.translation_key).ucFirst() : item[text]) : item[text] }}
                </div>
            </div>
        </transition-toggle>
    </span>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/bootstrap.scss" as colors;

.icon-list {
    top: calc(100% + 5px);
}

.icon-list-item:hover {
    background-color: lighten(colors.$primary, 25%) !important;;
    color: white;
}
</style>
